import React from "react";
import Shitz from "../images/shitz.png";
import { AnimationOnScroll } from 'react-animation-on-scroll';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDiscord } from '@fortawesome/free-brands-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
const Community = () => {
    return (
        <>
            <div id="community" 
            className="communityscreen"
            style={{backgroundImage:"url("+Shitz+")",
                    backgroundRepeat:"repeat"
                    }}
            >
                
                    <div className="center p-4">
                        <AnimationOnScroll initiallyVisible={true}
                        animateIn="animate__heartBeat"
                        >
                            <h1 className="text-center">Community</h1>
                            <p className="h5">
                                The Shitz community exists to improve digestive health through laughter, education, and charity.  
                                We hope our community becomes a forum for people with digestive diseases and conditions to openly discuss their experiences and support others with similar problems.  
                                Together we will prosper as we help others with digestive diseases.
                            </p>
                        </AnimationOnScroll>
                        <div className="text-center">
                            <a href="https://discord.gg/theshitz" 
                                className="btn btn-primary btn-discord"
                                style={{margin:'auto'}}
                                target="_blank"
                                rel="noreferrer"
                            >
                               <FontAwesomeIcon 
                                style={{color:'#1C1DE2',
                                        fontSize:'3rem',
                                        verticalAlign:'middle'
                                      }} 
                                icon={faDiscord as IconProp}
                                /> &nbsp; 
                                Join Discord
                            </a>
                        </div>
                        
                    </div>
                
            </div>
        </>
    );
}

export default Community;