import React, {FC} from 'react';
import '@solana/wallet-adapter-react-ui/styles.css';
import "./css/bootstrap.min.css";
import "./css/timeline.css";
import "animate.css/animate.min.css";


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./css/jessica.css";
import '@fortawesome/fontawesome-svg-core/styles.css'

import Context  from './Context';


import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';

import * as anchor from '@project-serum/anchor';

import Navigation from "./Components/Navigation";
import Footer from "./Components/Footer";
import Landing from "./Pages/Landing";
import Mint from "./Pages/Mint";
import SetUpPhantom from "./Pages/SetUpPhantom";

import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material/styles';
const theme = createTheme();
const getCandyMachineId = (): anchor.web3.PublicKey | undefined => {
  try {
    const candyMachineId = new anchor.web3.PublicKey(
      process.env.REACT_APP_CANDY_MACHINE_ID!,
    );

    return candyMachineId;
  } catch (e) {
    console.log('Failed to construct CandyMachineId', e);
    return undefined;
  }
};

const candyMachineId = getCandyMachineId();
const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST!;
const connection = new anchor.web3.Connection(
  rpcHost ? rpcHost : anchor.web3.clusterApiUrl('devnet'),
);

const txTimeoutInMilliseconds = 30000;

function App() {

  return (
    <ThemeProvider theme={theme}>
    <Context>
       <Router>
            <Navigation walletConnect={<Content />} />
            <Routes>
              <Route path="/" element={<Landing />} />
              <Route path="/mint" element={
                                            <Mint 
                                            candyMachineId={candyMachineId}
                                            connection={connection}
                                            txTimeout={txTimeoutInMilliseconds}
                                            rpcHost={rpcHost}
                                            />
                                          } />
              <Route path="/learn-setup-phantom-wallet" element={<SetUpPhantom />} />
            </Routes>
            <Footer />
       </Router>
    </Context>
    </ThemeProvider>
  );
}

const Content: FC = () => {
  return <WalletMultiButton className="custom_btn"/>;
};

export default App;
