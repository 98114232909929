import React from "react";
import {Container,Row,Col, Card} from "react-bootstrap";
import Shitz from "../images/shitz.png";
import { AnimationOnScroll } from 'react-animation-on-scroll';

const WhoTheShitz = () =>{
    return (
        <div className="whoscreen" id="who-are-the-shitz"
            style={{backgroundImage:"url("+Shitz+")",
                    backgroundRepeat:"repeat"
                    }}
        >
            <Container>
                <Row>
                    <Col lg={12} className="text-center whocard-container">
                        <AnimationOnScroll initiallyVisible={true} animateIn="animate__flash">
                            <Card  className="whocard">
                                <Card.Body>
                                    <Card.Title><h1>Who are The Shitz?</h1></Card.Title>
                                    <p className="m-4">
                                        The Shitz are a species of Homo feceans that comprise a complex society of 4,444 Shitizen. Each Shitizen is unique and represents a different character in Shitzville which exists on the Solana blockchain.
                                    </p>
                                    <p className="m-4">
                                    There are many different kinds of Shitz. The Shitz can be male or female and come in many shitzy colors, just like human shit. 
                                    All Shitz are well-formed and firm yet squishable. 
                                    The Shitz have been created to make you laugh because humor is great medicine to help us get through these Shitzy times. 
                                    A portion of Shitz sales will go to digestive disease charities and the Shitizens’ Community Bank for future Shitzy giveaways and projects!
                                    </p>
                                </Card.Body>
                            </Card>
                        </AnimationOnScroll>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default WhoTheShitz;