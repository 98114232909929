import React,{useState} from "react";
import {Modal,Card,Col,Container,Row} from "react-bootstrap";
import ReactCardFlip from 'react-card-flip';
import {Shit} from './HowManyShitz';
import ShitBG from '../images/shitz-bg.png'; 

export interface ShitProps {
    title: string;
    description:string;
    src: string;
    show:boolean;
    handleClose: Function;
    Shit: Shit | undefined;
    
}

const ShitModal = (props: ShitProps) => {
    const [flipped,setFlipped] = useState(false);
    const handleClick = (e:any) => {
        e.preventDefault();
        setFlipped(!flipped);
    }
    return (
        <Modal show={props.show} 
                onHide={() => props.handleClose()}
            className="shit-modal"
            size="xl"
        >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body 
            className="text-center"
            >
                <Container>
                    <Row>
                        <Col 
                            sm={{span:8,offset:2}} 
                            md={{span:6,offset:3}}
                             lg={{span:4,offset:4}}
                        >
                                <Col style={{margin:'auto'}}>
                                <ReactCardFlip
                                    isFlipped={flipped}
                                    flipDirection="horizontal"
                                    flipSpeedBackToFront={0.4}
                                    flipSpeedFrontToBack={0.4}
                                >
                                    <Card 
                                    style={{cursor:'pointer'}}
                                    onClick={e => handleClick(e)}
                                    className="card-how-many"
                                    >
                                        <Card.Img variant="bottom" src={props.src}/>
                                            <h5 className="card-title text-center">{props.Shit?.name}</h5>
                                            <span className={"card-type "+props.Shit?.class}>
                                                <div>
                                                    {props.Shit?.type}
                                                </div>
                                            </span>
                                    </Card>
                                    <Card
                                    style={{cursor:'pointer',backgroundImage:`url(${ShitBG})`}}
                                    onClick={e => handleClick(e)}
                                    className="card-back-how-many"
                                    >
                                       
                                            <span className={"card-type card-back-name h5"}>
                                                <div>
                                                    {props.Shit?.name}
                                                </div>
                                            </span>
                                            <span className={"card-type card-back-type h5"}>
                                                <div>
                                                    {props.Shit?.type}
                                                </div>
                                            </span>
                                        <Card.Body>
                                            <p className="mt-4">{props.description}</p>
                                        </Card.Body>
                                    </Card>
                                </ReactCardFlip>
                            </Col>
                        </Col>
                    </Row>
                </Container>
                
            </Modal.Body>
        </Modal>
    );
}

export default ShitModal;