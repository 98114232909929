import React from "react";
import {Container, Row, Col} from 'react-bootstrap';
import LightBrownPoop from '../images/light-brown-poop.png';
import DarkBrown from '../images/dark-brown.png';
import BlackPoop from '../images/black-poop.png';
import { AnimationOnScroll } from 'react-animation-on-scroll';

const Roadmap2 = () => {
    return (
        <div id="roadmapscreen2">
            <Container>
                <Row>
                    <AnimationOnScroll animateIn="animate__fadeInRight">
                        <Col lg={{span:6,offset:6}}
                            className="poop-container poop-c4"
                            style={{ backgroundImage:`url(${LightBrownPoop})` }}
                        >
                            <div className="poop poop-light">
                                <div className="h6">50% SOLD!</div>
                                We begin to develop our merch store full of Shitz!
                            </div>
                        </Col>
                    </AnimationOnScroll>
                </Row>
                <Row>
                    <AnimationOnScroll animateIn="animate__fadeInLeft">
                        <Col lg={6}
                            className="poop-container poop-c5 text-end"
                            style={{ backgroundImage:`url(${DarkBrown})` }}
                        >
                            <div className="poop">
                                <div className="h6">75% SOLD!</div>
                                Donate another 10% of sales to digestive disease charities.
                            </div>
                        </Col>
                    </AnimationOnScroll>
                </Row>
                <Row>
                    <AnimationOnScroll animateIn="animate__fadeInRight">
                        <Col lg={{span:6,offset:6}}
                            className="poop-container poop-c6"
                            style={{ backgroundImage:`url(${BlackPoop})` }}
                        >
                            <div className="poop">
                                <div className="h6">100% SOLD!</div>
                                Build a community DAO and begin development on TheShitz Cartoon. 
                                The community of Shitz holders will vote on all decisions of the franchise.
                            </div>
                        </Col>
                    </AnimationOnScroll>
                </Row>
            </Container>
        </div>
    );
}

export default Roadmap2;