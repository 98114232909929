import React from "react";
import Container from "react-bootstrap/Container";


const TheFooter = () => {
    return (
            
            <Container>
               <p className="mt-4 text-center" style={{color:"#808080"}}> TheShitz Copyright ©2022 All rights reserved</p>
            </Container>
            
    );
}

export default TheFooter;