import React,{useState} from "react";

import {Container, Row, Col, Card} from "react-bootstrap";
import Mayor from '../images/outfit-mayor.png';
import Sol from '../images/outfit-sol.png';
import Hunter from '../images/outfit-hunter.png';
import FastFood from '../images/outfit-fastfood.png';
import Convict from '../images/outfit-convict.png';
import Rancher from '../images/outfit-rancher.png';
import Nurse from '../images/outfit-nurse.png';
import RockStar from '../images/outfit-rockStar.png';
import Gym from '../images/outfit-gym.png';
import Comedian from '../images/outfit-comedian.png';
import Boxer from '../images/outfit-boxer.png';
import Electrician from '../images/outfit-electrician.png';
import Slider from "react-slick";

import ShitModal from "./ShitModal";
import ShitzBG from '../images/shitz-bg.png';
import { AnimationOnScroll } from 'react-animation-on-scroll';
export interface Shit{
    name: string;
    description: string;
    src: string;
    type:string;
    class:string;
}

const Shitz : Shit[] = [
    {
        name:'Big Shitz',
        description:'These Shitz are kings and queens, politicians, celebrities, and business titans. They wear the finest clothes and accessories. They can be composed of precious metal or gemstone. They drive hot cars, drink top-shelf liquor (Shitzkey), smoke the finest cigars, and are usually found at the most happening places in Shitzville. They all hold positions of power in Shitziety. The Big Shitz have it all, and don’t really care about the other Shitz in Shitzville.',
        src: Mayor,
        type: 'Legendary',
        class:'legendary',
    },
    {
        name:'Shitz-Outta-Luck',
        description:'If the Big Shitz are at the top, then these Shitz are at the bottom of the Shitzpile. The Shitz Outta Luck are the poorest and unluckiest Shitz in Shitzville. They wear cheap clothes and live in low-income apartments, shacks, trailers, or out on the street. They like to accessorize, but they resort to cheaper items and home-made bling. They drink Shitzy cola, Shitz Beer, or Wine Shitzer. They hang out in bars, street corners, trailer parks, and parking lots. The Shitz-Outta-Luck survive day-by-day, so many of them are sad, angry, or are just fresh out of give-a-shit.',
        src: Sol,
        type:'Super Rare',
        class:'super-rare'
    },
    {
        name:'Up-Shitz-Creekers',
        description:'The Up-Shitz-Creekers live beyond the outskirts of Shitzville and live mostly solitary lives in No-Shitz-Land. They are preppers and survivors living off the grid. They dress in comfortable and practical clothes and carry tactical gear, weapons, and tools for survival in the wilderness. They live off the land, so you may see undigested Shitzberry pits in these Shitz. Most of them grew tired of dealing with the same old Shitz every day. They are happy with the arduous solitary lifestyle they have chosen.',
        src: Hunter,
        type:'Super Rare',
        class:'super-rare'
    },
    {
        name:'Get-off-the-Pot Shitz',
        description:'These Shitz are thankfully few in number. These Shitz are indecisive young Shitz. They should be acting like adult Shitz but instead continue to act like little Shitz. Some still live at home with their parShitz. Others can’t seem to figure out what their purpose os in Shitzville. They work at odd jobs because they haven’t chosen careers. Some are still in school, staying in “safe” relationships, but never committing to anything or anyone.',
        src: FastFood,
        type: 'Super Rare',
        class:'super-rare'
    },
    {
        name:'Shitztormz',
        description:'The Shitztorms are very bad Shitz. Where they go, trouble follows. They are violent criminals. They have antiShitzal shitzonalities. Fortunately, they are super rare. They commit all types of crimes.',
        src: Convict,
        type:'Super Rare',
        class:'super-rare'
    },
    {
        name:'Shitz Kickers',
        description:'The Shitz Kickers live on the outskirts of Shitzville, really in the country. Shitz Kickers can have any type of occupation, but most of them are farmers, ranchers, cowboys and cowgirls, and oilers. They can have variable social status and wealth. Some are owners, and some are workers. Because of their easy access to fresh vegetables, it is not uncommon to see undigested corn kernels and other vegetarian ruffage in these Shitz.',
        src: Rancher,
        type:'Rare',
        class:'rare'
    },
    {
        name:'Give-A-Shitz',
        description:'The Give-A-Shitz are the lifeblood of Shitzville. They make the Shitz turn. They are physicians, nurses, police officers, firefighters, social workers, teachers, veterinarians, and paramedics. These Shitz really care about all the other Shitz, sometimes to their own detriment. They don’t go out for fun too much because they are always working. That’s why they are usually seen wearing their work clothes. Shitzville would go to shit without them.',
        src: Nurse,
        type:'Rare',
        class:'rare'
    },
    {
        name:'Happening Shitz',
        description:'These Shitz are very cool and interesting Shitz. They are artists, musicians, actors, daredevils, writers, comedians, magicians, chefs, fashion designers, and professional athletes. These Shitz are The Shit! They bring joy and entertainment to the other Shitz in Shitzville!',
        src: RockStar,
        type:'Rare',
        class:'rare'
    },
    {
        name:'Hot Shitz',
        description:'These Shitz are glorious specimens to behold! They are lean, shapely, voluptuous (females), brawny (males), and all the other Shitz just want to fuck the Shitz out of them. Some of them train hard while others hardly train but still got it where it counts. The Hot Shitz look real good dressed or undressed and everything in between. You know what I mean!',
        src: Gym,
        type: 'Rare',
        class:'rare'
    },
    {
        name:'Bat Shitz',
        description:'These Shitz have eccentric personalities. Some are even certifiably insane. None of them are dangerous, but they do peculiar and outrageous things. Bat Shitz may be identifiable by bizarre facial expressions, odd clothing color combinations, or doing strange things in strange places. Shitzville would be a dull place without them.',
        src: Comedian,
        type: 'Rare',
        class:'rare'
    },
    {
        name:'Tough Shitz',
        description:'These Shitz don’t take any shit! They are badass and like to kick ass. A bar room brawl is their idea of a good time. They can be motorcycle gang members, construction workers, boxers, or wrestlers. You don’t want to mess with one of these Shitz. They’ll kick the shit out of you!',
        src: Boxer,
        type: 'Rare',
        class:'rare'
    },
    {
        name:'Jack Shitz and Jane Shitz',
        description:'These everyday Shitz are the salt of the earth. They have blue collar and grey collar occupations. They enjoy many different leisure activities. The Jack and Jane Shitz are not only the most common Shitz but also are the most diverse Shitz. They can appear almost anywhere because no matter what the event or location, Jack and Jane Shitz are there doing the necessary work behind the scenes. Shitzville would fall to pieces without them.',
        src: Electrician,
        type: 'Common',
        class:'common'
    },
];
const HowManyShitz = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3
      };
    const [shit, setShit] = useState<Shit>();
    const [shitProps,setShitProps] = useState({
                                            title:'',
                                            description:'',
                                            src:'',
                                            show: false
                                        });
    
    const handleClose = () => {
        setShitProps({...shitProps,show:false});
    }
    const handleShow = (item:Shit) => {
        setShit(item);
        setShitProps({
            title: item.name,
            description: item.description,
            src: item.src,
            show:true
        });
        
    }
    const animate = (number:number) => {
        return number % 2 === 0 ? 'animate__bounceInLeft':'animate__bounceInRight';
    }
    return (

        <div id="howmanyscreen"
            style={{
                backgroundImage:"url("+ShitzBG+")",
                backgroundSize:"cover",
                backgroundRepeat:'no-repeat'
            }}
        >
            <Container>
                <Row>
                    <Col lg={12}>
                        <h2 className="text-center">How Many Varieties of Shitz?</h2>
                        <br /><br />
                        <Slider {...settings}>
                            {
                                Shitz.map((item,idx) => 
                                    <AnimationOnScroll 
                                    animateIn={animate(idx)}
                                    key={"item-"+idx}
                                    >
                                        <Col className="text-center" >
                                                <Card
                                                className="card-how-many"
                                                
                                                onClick={() => handleShow(item)}
                                                >
                                                    <Card.Img variant="bottom" src={item.src}/>
                                                    <h5 className="card-title text-center">{item.name}</h5>
                                                    <span className={"card-type "+item.class}>
                                                        <div>
                                                            {item.type}
                                                        </div>
                                                    </span>
                                                </Card>
                                        </Col>
                                   </AnimationOnScroll>
                                )
                            }
                        </Slider>
                    </Col>
                </Row>
            </Container>
            <ShitModal 
            handleClose={handleClose}
            show={shitProps.show}
            title={shitProps.title}
            description={shitProps.description}
            src={shitProps.src}
            Shit={shit}
            />
        </div>
        
    );
};

export default HowManyShitz;