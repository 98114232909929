import React, {useState} from "react";
import {Container, Row, Col, Modal, Card} from "react-bootstrap";
import Cryptonaut from "../images/cryptonaut.png";
import Surgeon from "../images/outfit-surgeon.png";
import Pharaoh from "../images/outfit-pharaoh.png";
import ShitBG from '../images/shitz-bg.png'; 
import ShitHeader from '../images/dark-brown.png'; 
import { AnimationOnScroll } from 'react-animation-on-scroll';
interface Individual{
    title: string;
    type: string;
    description: string;
    src: string;
}
const MeetTheTeam = () => {
    const [title, setTitle] = useState('');
    const [type, setType] = useState('');
    const [desc, setDesc] = useState('');
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = (item: Individual) => {
        setTitle(item.title);
        setType(item.type);
        setDesc(item.description);
        setShow(true);
    };


    const Team: Individual[] = [
        {
            title:'The Cryptonaut',
            type: 'Founder',
            src:Cryptonaut,
            description: `The Cryptonaut is the founder of
            Cryptonaut Holdings, LLC, and the visionary of The Shitz. The Cryptonaut
            has been an emergency physician (Give-a-Shitz) for over 20 years. He is
            also an entrepreneur, cryptoasset investor/ enthusiast, martial artist
            (Tough Shitz), and backyard farmer (Shitz Kicker). Many people think
            The Cryptonaut is Bat Shitz, but he proudly accepts that as a badge of
            originality. We all have unique God-given qualities, talents, and purposes
            to be cultivated and shared with the world.`
        },
        {
            title:'CryptoDoc',
            type: 'Investor/Advisor',
            src: Pharaoh,
            description: `CryptoDoc has been a surgeon
            (Give-a-Shitz) for over 20 years, which is how long he’s been friends and
            business partners with the Cryptonaut. CryptoDoc is also an entrepreneur,
            cryptoasset investor/enthusiast, and martial artist (Tough Shitz).
            CryptoDoc introduced the Cryptonaut to NFTs in September of 2021 with
            the purchase of some Ethereans Official. Now CryptoDoc owns a Bored
            Ape (BAYC) which makes CryptoDoc a Big Shitz!`
        },
        {
            title:'Dr. SOL',
            type: 'Investor/Advisor',
            src: Surgeon,
            description: `Dr. SOL has been a surgeon
            (Give-a-Shitz) for over 20 years, which is how long he’s been friends and
            business partners with CryptoDoc and the Cryptonaut.`
        }
    ];
    return (
        <div id="teamscreen">
            <h2 className="text-center">Meet the team</h2>
            <AnimationOnScroll animateIn='animate__fadeInUp'>
                <div className="team-bar"></div>
            </AnimationOnScroll>
            <Container>
                <Row>
                    {
                        Team.map((item,idx) => 
                            
                                <Col md={4} sm={12} key={'docs-'+idx}>
                                    <AnimationOnScroll animateIn='animate__fadeInUp'>
                                        <Card className="docs-card m-3" 
                                        onClick={() => handleShow(item)}
                                        style={{zIndex:5}}
                                        >
                                            <div className="docs-title h4">
                                                <div>{item.title}</div>
                                            </div>
                                            <Card.Img variant="bottom" src={item.src}/>
                                            <div className="docs-type h4">
                                                <div>{item.type}</div>
                                            </div>
                                        </Card>
                                    </AnimationOnScroll>
                                </Col>
                        )
                    }
                    
                </Row>
            </Container>
            <Modal 
                    show={show} 
                   onHide={handleClose} 
                   className="shit-bg"
            >
                <Modal.Header closeButton
                    style={{
                        backgroundImage: `url(${ShitHeader})`
                    }}
                    className="shit-header"
                >
                    <Modal.Title>{title} ({type})</Modal.Title>
                </Modal.Header>
                <Modal.Body
                style={{backgroundImage:`url(${ShitBG})`}}
                >{desc}</Modal.Body>
            </Modal>
        </div>
    );
}

export default MeetTheTeam;