import React from "react";
import {Container, Row, Col} from "react-bootstrap";
import Anim from '../images/anim.gif';
import Plaque from '../images/dark-plaque-with-title.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPoo } from '@fortawesome/free-solid-svg-icons'
import Component13_1 from '../images/Component13-1.svg';
import { AnimationOnScroll } from 'react-animation-on-scroll';
const WhyBecomeShitz = () => {
    return (
        
            <div className="whyscreen" id="why-become-a-shitzholder">
                <Container>
                    <Row>
                    
                        <Col lg={12}>
                        <AnimationOnScroll
            animateIn="animate__backInUp"
        >
                            <Container>
                                <Row>
                                    <Col md={6} xs={12} className="d-flex">
                                        <img src={Anim} className="img-fluid" alt='The Shitz video'/>
                                    </Col>
                                    <Col md={6} xs={12} 
                                        style={{backgroundImage:`url(${Plaque})`,
                                                backgroundSize:'100%',
                                                backgroundRepeat:'no-repeat'
                                                }}
                                        className='why-become-hodler'
                                    >
                                        <ul style={{textAlign:'left'}} className="fa-ul why-become-list">
                                            <li >
                                                <span className="fa-li">
                                                    <FontAwesomeIcon style={{color:'#C48022'}} icon={faPoo}/>
                                                </span>
                                                We'll make you laugh until you shitz yourself!  And it's for a great cause.
                                            </li>
                                            <li>
                                                <span className="fa-li">
                                                    <FontAwesomeIcon style={{color:'#C48022'}} icon={faPoo}/>
                                                </span>
                                                View to donate utility - Show off your Shitz to friends and level up your NFT.  
                                                The more people see it the more we donate to charities on your behalf.
                                            </li>
                                            
                                            <li>
                                                <span className="fa-li">
                                                    <FontAwesomeIcon style={{color:'#C48022'}} icon={faPoo}/>
                                                </span>
                                                The more Shitz held by an individual the higher their vote counts. But ALL Shitz will have their say.
                                            </li>
                                            <li>
                                                <span className="fa-li">
                                                    <FontAwesomeIcon style={{color:'#C48022'}} icon={faPoo}/>
                                                </span>
                                                Shitz holders have 100% rights to use, sell, and profit from their own Shitz. ( See TOS )
                                            </li>
                                            <li>
                                                <span className="fa-li">
                                                    <FontAwesomeIcon style={{color:'#C48022'}} icon={faPoo}/>
                                                </span>
                                                Allow you to uniquely name your freshly minted Shitz.  The name you give your Shitz will stay with it forever, even if you sell it.
                                            </li>
                                        </ul>
                                        <div style={{position:'relative'}} className="text-center">
                                            <img src={Component13_1} 
                                            alt="and a ton more" 
                                            style={{margin:'auto',maxWidth:'45% !important'}}
                                            className="img-fluid"
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Container>          
                            </AnimationOnScroll>         
                        </Col>
                    </Row>
                </Container>
            </div>
    );
}

export default WhyBecomeShitz;