import React from 'react';
import Rectangle5 from '../images/Rectangle5.png';
import Toilet from '../images/toilet.svg';
import TheShitz from '../images/TheShitz.svg';
import LeftShitz from '../images/left-shitz.png';
import RightShitz from '../images/right-shitz.png';
import {Container, Row,Col} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import { AnimationOnScroll } from 'react-animation-on-scroll';

const Home = () => {
   
    
    return (
        <>
            <div id="home">&nbsp;</div>
            <div id="homescreen"
            style={{
                        backgroundImage:`url(${Toilet}),url(${Rectangle5})`,
                        backgroundSize:'50%, cover',
                        backgroundRepeat:'no-repeat',
                        backgroundPosition:'center center',
                 }}
            >
                <Container>
                    <Row>
                        <Col className="text-center" lg={12}>
                                <img src={TheShitz} alt="The Shitz" 
                                className='img-fluid' 
                                style={{margin:'10px auto'}}/>
                            
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6} className="text-end">
                            <AnimationOnScroll 
                            initiallyVisible={true}
                             animateOut="animate__rotateOutDownLeft"
                             animateIn='animate__bounceInDown'
                             >
                                <img src={LeftShitz} alt="Shitz Left" className='img-fluid' 
                                    style={{margin:'auto'}}
                                />
                            </AnimationOnScroll>
                        </Col>
                        <Col xs={6} style={{padding:0,margin:0}}>
                            <AnimationOnScroll 
                            initiallyVisible={true}
                            animateOut="animate__rotateOutDownRight"
                            animateIn='animate__bounceInDown'
                            >
                                <img src={RightShitz} alt="Shitz Right" className='img-fluid'/>
                            </AnimationOnScroll>
                        </Col>
                        
                    </Row>
                </Container>
                <Container>
                    <Row>
                        <Col sm={12} style={{position:'relative',height:'33vh'}}>
                            <div className="center text-center">
                                <h1>
                                    Fighting Digestive Disease one Shitz at a Time!
                                </h1>
                                <Link to="/mint" className="mint-btn btn">
                                    <span>Click to Mint</span>
                                </Link>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default Home;
